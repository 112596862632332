var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recognition-page-heng-container" },
    [
      _c("RecognitionStrokePage", {
        attrs: { backgroundImage: _vm.bgImg, strokeObj: _vm.strokeDetail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="lesson-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="3" />
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";
export default {
  data() {
    return {
      positionStr: "lesson",
      pageInfoObj: {
        pinyin: "Wǒ de péngyou",
        hanzi: "我的朋友",
        english: "My Friends",
        sound: require("@/assets/audio/L1/1-Chapter/chapter-3-title.mp3"),
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.lesson-container {
  width: 100%;
  height: 100%;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "target-container" },
    [
      _c("StartStop", {
        attrs: {
          position: _vm.positionStr,
          pageInfo: _vm.pageInfoObj,
          chapterNo: 3,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }